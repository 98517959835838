@use 'styles/colors' as colors;

.navContainer {
  @include colors.darkBg;
}

.waveBg{
  background: url('/backgrounds/installation-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;


  @media screen and (max-width: 1000px){
    background-size: auto;
  }

  @media screen and (min-width: 1600px){
    background-position-y: -150px;
  }
}