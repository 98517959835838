@use 'styles/colors' as colors;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.divider {
  width: 240px;
  height: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: colors.$accent-pearl;
}