@use 'styles/colors' as colors;
@use 'styles/fonts' as fonts;
@use 'styles/spacing' as spacing;
.textWrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}
.titleSubtitleWrapper{
    margin: 24px;
    text-align: start
}
.blogTitleSubtitleWrapper{
    margin-top: 27px;
}
.featuredHeader{
    margin-top:0px ;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    white-space: nowrap;
}
.icons {
    display: flex;
    align-items: center;
    gap: 15px;
}
.iconTextMobile{
    display: flex;
    align-items: center;
    gap:5px
}
.footerText{
    color:colors.$accent-mid-grey
}
.homePageFooterText{
    color:colors.$f-light-bg-paragraph
}
.titleContainer{
    display: inline;
}
.iconText{
    display: flex;
    align-items: center;
    gap:5px
}
.subtitle{
    margin-top: 10px;
}
.homePageSubtitle{
    margin-top: 10px;
    color: colors.$f-light-bg-paragraph;
}
.cardFooter{
    margin: 24px;
    margin-top:0px ;
    display: grid;
    grid-template-columns: repeat(1,3fr 1fr);
    align-items: center;
}
.readmore{
    color: colors.$core-yellow;
    font-weight: 600;
    text-align:start ;
}
.homePageReadMore{
    color: colors.$core-mid-purple;
    font-weight: 600;
    text-align:start ;
}
.footerText{
    color:colors.$accent-mid-grey
}
@media screen and (max-width:1000px) {
    .iconText{
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .featuredHeader{
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}