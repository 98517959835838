@use 'styles/spacing' as spacing;

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: spacing.$max-content-width;
  margin: auto !important;
}

.textContainer {
  flex-wrap: wrap;
}

.rtl {
  padding-right: 56px;
}

.paragraph {
  margin-right: 56px;
  flex-basis: 100%;
}

.image {
  width: 530px;
  height: 420px;
  position: relative;
}
.animation {
  flex-shrink: 0;
}
@media screen and (max-width: 1400px) and (min-width:1001px) {
  .image{
    width:40vw !important;
    height: 32vw !important;
    span{
    width:40vw !important;
    height: 32vw !important;
    }
  }
  .desktop{
    width:40vw !important;
    height: 32vw !important;
    video{   
    width:40vw !important;
    height: 32vw !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .paragraph {
    margin-bottom: 24px;
    margin-right: spacing.$mobile-column-margin;
    margin-left: spacing.$mobile-column-margin;
  }
  .textContainer{
    padding: 0 40px;
  }

}

@media screen and (max-width: 1000px) {
  .textContainer{
    padding: 0
  }
}

@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .image {
    position: unset;
    width: 100%;
  }
  .animation {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
}
