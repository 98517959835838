@use 'styles/colors' as colors;

.notificationBanner{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  gap: 40px;
  background: linear-gradient(270.01deg, #43B333 0.4%, #2C8820 99.99%);
  color: colors.$core-white;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  background-color: colors.$status-success;
  padding: 24px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 12px;
  }

  > a{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: #2C8820;
    background-color: colors.$core-white;
    border-radius: 100px;
    height: 30px;
    width: 140px;

    @media screen and (max-width: 768px) {
      height: 38px;
    }
  }
}

.closeBanner {
  position: absolute;
  right: 28px;
}