@use 'styles/colors' as colors;


.swiperContainer{
    width: 100%;
    max-width: 100%;
    touch-action: pan-y;
    overflow: hidden;
}
.swiperList{
    display: flex;
    min-width: 100%;
    padding: 0;
    list-style: none;
    flex-direction: row;
    margin: 0;
    transition: transform 0.3s ease-out;
}
.swiperItem{
    width: 100%;
    flex-shrink: 0;
    padding:0 8px;
}
.swiperList.swipping{
    transition: none;
}
.swiperIndicator{
    display: flex;

    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 40px;
    padding: 0;
}
.swipperIndicatorItem{
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: colors.$core-light-purple;
    margin: 0 8px;
}
.activeIndicator{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: colors.$core-yellow;
}
