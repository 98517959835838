@use 'styles/colors' as colors;
@use 'styles/fonts' as fonts;

.footer {
  @include colors.darkBg;
}

.linksContainer a {
  font-size: 14px;
  font-family: 'Inter';
  color: colors.$accent-mid-grey;
}

.ctaContainer {
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 32%;
}

.ctaBtn {
  @include fonts.btnText;
  @include colors.base-button;
  cursor: pointer;
  padding: 20px 36px;
  border-radius: 32px;
  margin-top: 32px;
  box-shadow: 0px 8px 32px #0d0825;
  font-weight: 700;
}

.linksContainer {
  height: 316px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.38;
}
.wrapper {
  width: 89%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 14px;
  justify-items: start;
  align-items: start;
}
.copyrightContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 89%;
  justify-content: start;
  justify-items: start;
  align-items: center;
}
.footerFooter {
  flex: 0.14;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}
.title {
  font-weight: 700;
}
.iconWrapper {
  background-color: colors.$icon-dark-bg;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.iconsWrapper {
  display: flex;
}
.footerColumn {
  display: grid;
  grid-gap: 12px;
}
.footerTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 96px;
  padding-bottom: 96px;
}
.footerTitleWrapper {
  height: 57%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .ctaContainer {
    width: 45%;
  }
}
@media screen and (max-width: 1000px) {
  .ctaContainer {
    width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .ctaContainer {
    width: 70%;
  }
  .ctaMobile {
    font-size: 28px;
  }

  .linksContainer {
    padding-top: 39px;
    margin-bottom: 40px;
    height: unset;
  }
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    grid-gap: 40px;
  }
  .logo {
    grid-row: 1;
    grid-column: 1/2;
  }
  .products {
    grid-row: 2;
  }
  .legal {
    grid-row: 2;
  }
  .support {
    grid-row: 3;
  }
  .icons {
    grid-row: 3;
  }
}
.copyrightText {
  grid-column: 1/5;
}
@media screen and (max-width: 600px) {
  .ctaContainer {
    width: 90%;
  }
}
