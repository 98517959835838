@use 'styles/colors' as colors;
@use 'styles/fonts' as fonts;
@use 'styles/spacing' as spacing;


.wrapper {
    min-width: 200px;
  }

  .titleContainer {
    width: 100%;
    min-height:calc(100vh - spacing.$max-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  
  .titleBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    top: 40%;
    width: 90%;
  }
  
  .title {
    @include fonts.header1;
    flex-basis: 100%;
    margin: 0;
    text-align: center;
  }
  .titleBodyContainer {
    padding-top: 35px;
    color: colors.$core-white;
  }
  .content {
    @include colors.lightBg;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container{
    width: 100%;
    padding: 126px 0px;
    padding-left: spacing.$column-margin;
    padding-right: spacing.$column-margin;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .titleBtn {
    all: unset;
    @include colors.base-button;
    @include fonts.btnText;
    cursor: pointer;
    padding: 20px 36px;
    border-radius: 32px;
    margin-top: 32px;
    box-shadow: 0px 8px 32px #0d0825;
    font-weight: 700;
  }
  .underline {
    text-decoration: underline;
    text-decoration: colors.$yellow-underline;
  }
  .ImageParagraphWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: spacing.$max-content-width;
    margin: auto;
  }
  .imgPrgTextWrapper{
    flex-wrap: wrap;
    width: 37vw;
  }  
  .paragraph {
    margin-right: 56px;
    flex-basis: 100%;
  }
  .logoWrapper {
    background: colors.$core-white;
    width: 187px;
    height: 114px;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }
  .titleWrapper {
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
  }
 .feauturesVidepPrgContainer{
   display: flex;
   flex-direction: column;
   gap: 50px;
 }
 .faqCards {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 30px;
  padding: 0 80px;
  padding-top: 85px;
  max-width: spacing.$max-content-width;
  margin: auto;
  margin-bottom: 220px;
}
.fullWidth {
  width: 100%;
}

.faqCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
  .faqCard a {
    text-decoration: underline;
  }
  .learnMoreContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: spacing.$column-margin;
    padding-right: spacing.$column-margin
  }
  .featureLearnMore {
    padding-top: 25px;
    text-align: center;
  }
  .logosWrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    justify-content: space-around;
    max-width: spacing.$max-content-width;
  }
  .compatible {
    padding-top: 97px;
    padding-bottom: 191px;
    direction: rtl;
  }
  .compatible :last-child {
    margin-right: 56px;
    div{
      div{
        margin-right: unset;
      }
    }
  }
  .compatible :first-child {
    margin-right: 5px;
  }
  .compatibleTextContainer {
    direction: ltr;
    text-align: start;
  }
  .image {
    position: relative;
    min-width: 521px;
    text-align: -webkit-right;
    flex: 1;
  }
  .compatible .titleRtl {
    justify-content: flex-end;
    text-align: flex-end;
    a{
      color:colors.$core-mid-purple; ;
    }
  }
  .learnMoreBtn {
    all: unset;
    @include fonts.btnText;
    cursor: pointer;
    padding: 20px 36px;
    border-radius: 32px;
    margin-top: 32px;
    background: colors.$core-deep-violet;
    box-shadow: 0px 10px 20px rgba(136, 63, 133, 0.15);
    color: colors.$core-white;
    text-align: center;
  }
  .featuredCard{
    width: 430px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    background-color: colors.$core-white;
    border-radius: 16px;
    overflow: hidden;
}
.featuredCardsWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:24px
}
@media screen and (max-width: 1400px) {
  .image{
    width:40vw;
    height: 32vw ;
    min-width: unset;
    max-width: unset;
  }
}
@media screen and (max-width:1100px) {
 
  .swiperComponentContainer{
      width: 400px;
      margin: auto;
   }
   .logosWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
   .featuredCardMobile{
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: colors.$core-white;
    border-radius: 16px;
    overflow: hidden;
    margin: auto;
}}
  @media screen and (max-width: 1000px) {
    .mobileParagraph{
      margin-bottom: 24px;
      margin-right: min(12%, 30px);
      margin-left: min(12%, 30px);
    }
    .container{
      padding: 50px 0px;
    }
    .compatible :last-child {
      margin-right: unset;
    }
    .imgPrgTextWrapper{
      width: unset;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap:24px;
      padding: 0 40px;

    }
    .ImageParagraphWrapper {
      flex-direction: column;
    }
    .faqCards {
      flex-direction: column;
    }
    .image{
      width: 521px;
    }
   
  }
  @media screen and (max-width: 800px) {
    .faqCards {
      display: flex;
      justify-content: space-between;
      padding-top: 85px;
    }
  
    .faqCards .faqCard {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
    .logosWrapper {
      grid-template-columns: repeat(3, 1fr);
    }
    .imgPrgTextWrapper{
      padding: 0;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 600px) {
    
    .image {
      position: unset;
      width: 100%;
      text-align: -webkit-right;
      flex: 1;
    }
    .swiperComponentContainer{
      width: 274px;
   }
   .featuredCardMobile{
      max-width: 274px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: colors.$core-white;
      border-radius: 16px;
      overflow: hidden;
      margin: auto;
  }
    .titleBlock {
      padding-right: spacing.$mobile-column-margin;
      padding-left: spacing.$mobile-column-margin;
    }
    .learnMoreContainer {
      padding-right: spacing.$mobile-column-margin;
      padding-left: spacing.$mobile-column-margin;
    }
    .partnerContainer {
      padding-left: spacing.$mobile-column-margin;
      padding-right: spacing.$mobile-column-margin;
    }
  
    .logosWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 450px) {
    .titleContainer {
      min-height: calc(100vh + spacing.$max-header-height);
    }
  
    .titleBlock {
      top: 2*spacing.$max-header-height;
    }
  
    .logosWrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  :export {
    smallHeader: fonts.$small-header;
  }